import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../core/src/stores';
import { countryCodes } from '../stores/Constants';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Image from './../core/src/components/common/Image';
import Logo from './../core/src/components/common/Logo';
import LinkMui from '../core/src/components/common/Link';
import { detectAlpha2Country } from '../utils/country';
import Link from './../utils/Link';
import Picture from './../utils/Picture';
import Text from './../utils/Text';
import Dropdown from './../utils/Dropdown';
import './../assets/style/modules/Header.scss';

const useStyles = makeStyles(() => ({
  linkMui: {
    textDecoration: 'none',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#3b4154',
    '& span': {
      marginBottom: '-5px',
      display: 'block',
    },
  },
}));

const HeaderModule = (props) => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState({
    open: false,
    index: null,
  });
  const [openPopupProfile, setOpenPopupProfile] = useState(false);

  // Menu categories
  const [category, setCategory] = useState('');
  const [heightCategory, setHeightCategory] = useState(0);
  const profilePopupRef = useRef(true);
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef(null);

  // User area menu  settings
  const { orderStore, userStore } = useStores();
  const { hasOrders, currentOrder, currentIsModelKit } = orderStore;
  const { user } = userStore;

  const links = [
    {
      label: t('subscription'),
      path: '/myarea/subscription',
      subscriptionRequired: true,
      alignRight: false,
      mobile: true,
    },
    currentIsModelKit
      ? {
          label: t('assembleTutorials'),
          path: '/myarea/video-tutorials',
          subscriptionRequired: true,
          alignRight: false,
          mobile: true,
        }
      : undefined,

    currentOrder &&
      !currentIsModelKit && {
        label: currentIsModelKit ? t('model') : t('collection'),
        path: '/myarea/collection',
        subscriptionRequired: true,
        alignRight: false,
      },

    {
      label: t('helpCenter'),
      path: '/myarea/help-center',
      subscriptionRequired: false,
      alignRight: false,
      mobile: true,
    },

    {
      label: t('profile'),
      path: '/myarea/profile',
      subscriptionRequired: false,
      alignRight: true,
      mobile: false,
    },
  ];

  useEffect(() => {
    document.body.className = `${isMenuOpened ? 'no-overflow-body' : ''}`;

    const header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
    };

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [isMenuOpened, isCategoryOpen]);

  const handleScroll = (elTopOffset, elHeight) => {
    if (window.scrollY || document.documentElement.scrollTop > elTopOffset + elHeight) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  const getElementProp = (type) => {
    let content = null;
    let arr = [];
    for (let key in props) {
      if (
        props[key] != null &&
        typeof props[key] === 'object' &&
        props[key].type === type
      ) {
        content = props[key].content || [];
        arr.push(...content);
      }
    }

    return arr || [];
  };

  const menu = getElementProp('HeaderMenuItem');
  const topBar = getElementProp('HeaderAnnouncement');
  const footerServices = getElementProp('HeaderServices');
  const breadcrumbs = (props.info || {}).breadcrumbs;
  const toggleMenuMobile = (event) => {
    event.preventDefault();
    setIsMenuOpened(!isMenuOpened);
  };

  const categoryMenu = (categoryField, height, index) => {
    setIsCategoryOpen({ open: true, index: index });
    setCategory(categoryField);
    setHeightCategory(height);
  };

  return (
    <React.Fragment>
      {topBar &&
        topBar.map((bar, index) => {
          return (
            <div key={index} className="Header Header__topbar bg-grey02">
              <div className="d-flex align-items-center flex-direction-row w-100 h-100 justify-content-center">
                <Text
                  className="d-inline Poppins text-grey05"
                  text={bar.text?.field_value}></Text>
                <Link
                  values={{
                    ...bar.cta?.field_value,
                    rel: 'nofollow',
                  }}
                  className="cta d-inline ml-1 text-decoration-none Poppins line-h-1">
                  {bar.cta?.field_value.label}
                </Link>
              </div>
            </div>
          );
        })}

      {/* Menu nav fixed on mobile */}
      {isMenuOpened && (
        <div className="Header__mobile-nav bg-green02 d-flex flex-direction-column justify-content-between">
          <div className="px-2 position-relative">
            <div className="container">
              <div className="d-flex flex-direction-column justify-content-between">
                <div className="d-flex justify-content-between align-content-center my-5">
                  <h1 className="align-self-center m-0">
                    <Logo className="mobile-logo-nav">logo-white</Logo>
                  </h1>

                  <Image
                    onClick={toggleMenuMobile}
                    name="close_white"
                    className="align-self-center"></Image>
                </div>

                <div className="Header__mobile-nav-dropdown">
                  {(menu || []).map((element, index) => {
                    if (element.item || element.product) {
                      return (
                        <Dropdown
                          key={index}
                          titleClass={
                            'dropdown-title text-grey01'
                          }
                          className="mb-4 position-relative"
                          title={element.label_mobile?.field_value}
                          color="grey01"
                          titleBold
                          dropdownIcon="dropdown-down-white">
                          <ul className="py-1 mt-2">
                            {element.product &&
                              (element.product?.field_value || []).map(
                                (item, index) => (
                                  <li key={index} className="mb-3">
                                    <Link
                                      internalLink={true}
                                      values={
                                        item?.field_value.product?.field_value
                                          ?.menu_cta?.field_value
                                      }
                                      className="text-decoration-none Poppins text-grey01">
                                      {item.field_value.product.field_value
                                        .menu_short_title.field_value?.label
                                        ? item.field_value.product.field_value
                                            .menu_short_title?.field_value
                                        : item.field_value.product.field_value
                                            ?.menu_title?.field_value}
                                    </Link>
                                  </li>
                                ),
                              )}
                            {element.item &&
                              (element.item?.field_value || []).map(
                                (item, index) => {
                                  if (
                                    item.field_value.cta.field_value.style ===
                                    'icon'
                                  ) {
                                    return (
                                      <li key={index} className="mb-3">
                                        <Link
                                          internalLink={true}
                                          values={
                                            item.field_value.cta?.field_value
                                          }
                                          className="text-decoration-none Poppins cta-desktop cursor-pointer w-100 text-decoration-none d-flex flex-direction-row justify-content-between align-items-center">
                                          <div className="d-flex flex-directio-row">
                                            <span className="text-rose01 btn__label">
                                              {
                                                item.field_value.cta
                                                  ?.field_value?.label
                                              }
                                            </span>

                                            {item.field_value.icon && (
                                              <Picture
                                                className="dropdown__img"
                                                image={
                                                  item.field_value.icon
                                                    ?.field_value
                                                }
                                              />
                                            )}
                                          </div>

                                          <Image name="arrow-right-yellow"></Image>
                                        </Link>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li key={index} className="mb-3">
                                        <Link
                                          internalLink={true}
                                          values={
                                            item.field_value.cta?.field_value
                                          }
                                          className="text-decoration-none Poppins text-grey01">
                                          {
                                            item.field_value.cta?.field_value
                                              ?.label
                                          }
                                        </Link>
                                      </li>
                                    );
                                  }
                                },
                              )}
                          </ul>
                        </Dropdown>
                      );
                    } else {
                      return (
                        <div className="mb-3">
                          <Link
                            internalLink={true}
                            values={element.cta?.field_value}
                            className="text-decoration-none d-flex flex-direction-row justify-content-between w-100">
                            <div className="d-flex flex-direction-row align-items-center">
                              <span className=" dropdown-title text-grey01">
                                {element.cta.field_value?.title}
                              </span>

                              {element.icon?.field_value && (
                                <Picture
                                  className="dropdown__img"
                                  image={element.icon?.field_value}
                                />
                              )}
                            </div>
                            <Image name="arrow-right-white-small"></Image>
                          </Link>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* Mobile footer */}
          <div className="Header__mobile-nav-footer p-3 border-top d-flex justify-content-center fs-16 Poppins text-grey01">
            <div>
              {footerServices &&
                footerServices[0] &&
                footerServices[0].mobileFooter &&
                footerServices[0].mobileFooter?.field_value &&
                footerServices[0].mobileFooter?.field_value.map(
                  (footerField, index) => {
                    return (
                      <Link
                        key={index}
                        className="px-2 text-decoration-none Poppins text-grey01"
                        values={footerField.field_value.cta?.field_value}>
                        {footerField.field_value.cta?.field_value?.label}
                      </Link>
                    );
                  },
                )}
            </div>
          </div>
        </div>
      )}

      <div
        id="sticky-header"
        className={`Header Header__container ${
          sticky.isSticky ? 'menu-sticky' : ''
        } ${
          props.info.black_header && props.info.black_header === '1'
            ? 'bg-green03'
            : ''
        }`}
        ref={headerRef}>
        <div className="container">
          <div className="row">
            <div className="col-24">
              <div className="Header__nav px-2 px-md-2 d-flex justify-content-between align-items-center">
                {/* Hamburger menu */}
                <div className="d-flex h-100">
                  <div
                    className="Header__ham-menu align-items-center"
                    onClick={toggleMenuMobile}>
                    <Image className="Header__menu-icon" name="menu_white" />
                  </div>

                  {/* Logo */}
                  <h1 className="Header__logo d-flex align-items-center ml-2 ml-lg-0 my-0">
                    <Logo className="desktop-logo-nav">logo-white-full</Logo>
                  </h1>
                </div>

                <div className="d-flex h-100">
                  {/* Menu groups - desktop */}
                  <div
                    className="Header__menu-groups h-100"
                    onMouseLeave={() =>
                      setIsCategoryOpen({
                        ...isCategoryOpen,
                        open: false,
                      })
                    }>
                    <ul className="h-50 mr-4 align-items-center">
                      {menu &&
                        (menu || []).map((element, index) => {
                          return (
                            <li
                              key={index}
                              className="menuGroups__item">
                              <a
                                id={'dropdownTargetHeight' + index}
                                className="text-decoration-none Poppins text-grey01"
                                href={element?.cta?.field_value?.href}
                                onMouseEnter={() => {
                                  categoryMenu(
                                    element?.cta?.field_value?.label,
                                    document
                                      .getElementById(
                                        'dropdownTargetHeight' + index,
                                      )
                                      .getBoundingClientRect().left,
                                    index,
                                  );
                                }}
                                hrefLang={element?.cta?.field_value?.hrefLang}
                                title={element?.cta?.field_value?.title}
                                target={element?.cta?.field_value?.target}>
                                <span className="text-center">{element?.cta?.field_value?.label}</span>
                                {element.icon?.field_value && (
                                  <span>
                                    <Picture
                                      className="icon-label d-inline"
                                      image={element.icon?.field_value}
                                    />
                                  </span>
                                )}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center d-lg-none h-100">
                  <div className="d-flex align-items-center position-relative">
                    {window.gproxy.config.lang() !== countryCodes.hu && (
                      <a
                        className="d-flex align-items-center ml-3"
                        onClick={() => {
                          if (!window?.gproxy?.session?.valid()) {
                            window.location.href = '';
                          } else {
                            setOpenPopupProfile(!openPopupProfile);
                          }
                        }}
                        rel="nofollow">
                        <Image className="icon-24" name="user-white"></Image>
                      </a>
                    )}
                    <SwipeableDrawer
                      className="d-block d-lg-none"
                      anchor={'right'}
                      open={openPopupProfile}
                      onClose={() => setOpenPopupProfile(false)}
                      onOpen={() => setOpenPopupProfile(true)}>
                      <div className="user-area__mobile h-100">
                        <List className="shadow-1">
                          <ListItem className="">
                            <ListItemText
                              className="d-flex flex-direction-row justify-content-between align-items-center"
                              primary={
                                <span className="user-area__user-name">
                                  {t('common:hiUser', {
                                    name: user?.name || '',
                                  })}
                                </span>
                              }
                              secondary={
                                window.gproxy.config.lang() !==
                                  countryCodes.hu && (
                                  <Image
                                    onClick={() => setOpenPopupProfile(false)}
                                    className="icon-24"
                                    name="close"
                                  />
                                )
                              }
                            />
                          </ListItem>
                        </List>

                        <List dense={true} className="user-area__list">
                          {links
                            .filter(
                              (l) =>
                                !!l &&
                                ((l.subscriptionRequired && hasOrders) ||
                                  !l.subscriptionRequired) &&
                                l.mobile,
                            )
                            .map((l, key) => (
                              <ListItem className="user-area__item" key={key}>
                                <ListItemText
                                  onClick={() => {
                                    setOpenPopupProfile(false);
                                    window.location.href =
                                      `/${detectAlpha2Country()}` + l.path;
                                  }}
                                  className="d-flex flex-direction-row align-items-center justify-content-between py-2 list-item-text"
                                  primary={
                                    <LinkMui
                                      className={classes.linkMui}
                                      href={
                                        `/${detectAlpha2Country()}` + l.path
                                      }
                                      title={l.label}></LinkMui>
                                  }
                                  secondary={
                                    <LinkMui
                                      className={classes.linkMui}
                                      href={
                                        `/${detectAlpha2Country()}` + l.path
                                      }
                                      title={
                                        <Image
                                          className="icon-16"
                                          name="arrow-right-yellow-small"
                                        />
                                      }></LinkMui>
                                  }
                                />
                              </ListItem>
                            ))}
                        </List>
                      </div>
                      <div className="w-100 bg-grey01 ">
                        <List dense={true}>
                          {links
                            .filter((n) => n?.path === '/myarea/profile')
                            .map((link, index) => {
                              return (
                                <ListItem key={index}>
                                  <ListItemText
                                    onClick={() =>
                                      (window.location.href = link.path)
                                    }
                                    className="cursor-pointer d-flex flex-direction-row align-items-center"
                                    secondary={
                                      <Link
                                        className="user-area__link-desktop text-grey05 ml-1"
                                        values={{
                                          href: link.path,
                                        }}>
                                        {link.label}
                                      </Link>
                                    }
                                    primary={
                                      <Image
                                        className="icon-16"
                                        name="user-black"></Image>
                                    }
                                  />
                                </ListItem>
                              );
                            })}

                          <ListItem>
                            <ListItemText
                              className="cursor-pointer d-flex flex-direction-row align-items-center"
                              onClick={() => {
                                setOpenPopupProfile(false);
                                userStore.logout();
                              }}
                              secondary={
                                <span className="user-area__link-desktop text-grey05 ml-1">
                                  {t('common:logout')}
                                </span>
                              }
                              primary={
                                <Image
                                  name="logout_black"
                                  className="user-area__logout-icon icon-16"></Image>
                              }></ListItemText>
                          </ListItem>
                        </List>
                      </div>
                    </SwipeableDrawer>
                  </div>
                </div>

                {/* Icons profile and search for desktop */}
                <div className="Header__profile-search d-none d-lg-block">
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Profile icon */}
                    <div className="d-flex align-items-center position-relative ml-3 ml-md-3 ml-lg-4">
                      {window.gproxy.config.lang() !== countryCodes.hu &&
                        window.location.pathname !== '/fr/myarea/distributor' &&
                        window.location.pathname !== '/es/myarea/distributor' &&
                        window.location.pathname !== '/be/myarea/distributor' && (
                          <a
                            className="d-flex align-items-center"
                            onClick={() => {
                              if (!window?.gproxy?.session?.valid()) {
                                window.location.href =
                                  window.location.origin +
                                  '/' +
                                  detectAlpha2Country() +
                                  '/myarea';
                              } else {
                                setOpenPopupProfile(!openPopupProfile);
                              }
                            }}
                            rel="nofollow">
                            <Image
                              className="icon-24 cursor-pointer"
                              name="user-white"
                            />
                          </a>
                        )}
                      {openPopupProfile && (
                        <div className="profile-dropdown" ref={profilePopupRef}>
                          <div className="d-flex flex-direction-row justify-content-end w-100 ">
                            <div className="profile-arrow w-100 bg-grey01 shadow-2"></div>
                          </div>
                          <div className="bg-white w-100  shadow-2 text-grey05">
                            <List dense={true} className="user-area__list">
                              {links
                                .filter(
                                  (l) =>
                                    !!l &&
                                    ((l.subscriptionRequired && hasOrders) ||
                                      !l.subscriptionRequired),
                                )
                                .map((l) => (
                                  <ListItem className="user-area__item">
                                    <ListItemText
                                      className="list-item-text"
                                      onClick={() =>
                                        (window.location.href =
                                          `/${detectAlpha2Country()}` + l.path)
                                      }
                                      primary={
                                        <LinkMui
                                          underline="none"
                                          className={classes.linkMui}
                                          href={`/${detectAlpha2Country()}` + l.path}
                                          title={l.label}></LinkMui>
                                      }
                                    />
                                  </ListItem>
                                ))}
                            </List>

                            <div className="w-100 bg-grey01 ">
                              <List dense={true}>
                                <ListItem>
                                  <ListItemText
                                    className="cursor-pointer d-flex flex-direction-row align-items-center"
                                    onClick={() => {
                                      setOpenPopupProfile(false);
                                      userStore.logout();
                                    }}
                                    secondary={
                                      <span className="user-area__link-desktop text-grey05 ml-1">
                                {t('common:logout')}
                              </span>
                                    }
                                    primary={
                                      <Image
                                        name="logout_black"
                                        className="user-area__logout-icon icon-16"
                                      />
                                    }
                                  />
                                </ListItem>
                              </List>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="position-relative d-none d-md-block"
          style={{ zIndex: 30 }}>
          {menu.map((field, index) => {
            if (category === field?.cta?.field_value?.title) {
              if (field.item) {
                return (
                  <div
                    onMouseEnter={() =>
                      setIsCategoryOpen({
                        ...isCategoryOpen,
                        open: true,
                      })
                    }
                    onMouseLeave={() =>
                      setIsCategoryOpen({
                        ...isCategoryOpen,
                        open: false,
                      })
                    }
                    key={index}
                    className={`Header__menu-desktop bg-grey01 shadow-2 ${
                      isCategoryOpen.open
                        ? 'Header__menu-desktop-open'
                        : 'Header__menu-desktop-close'
                    }`}>
                    <span
                      className="dropdown-selector-triangle bg-grey01 d-block"
                      style={{
                        left: heightCategory + 'px',
                      }}></span>
                    <div className="container d-flex justify-content-center flex-direction-column h-100">
                      <div className="row">
                        <div className="col-6">
                          <div className="px-2 px-xl-3 d-flex flex-direction-column Poppins h-100 justify-content-end">
                            {field.item?.field_value.map((item, itemIndex) => {
                              if (
                                item.field_value?.cta?.field_value.style &&
                                item.field_value?.cta?.field_value.style ===
                                  'icon' &&
                                itemIndex < 4
                              ) {
                                return (
                                  <div
                                    className="d-flex justify-content-between my-1 my-lg-3"
                                    key={itemIndex}>
                                    <Link
                                      values={item?.field_value.cta?.field_value}
                                      className="cta-desktop cursor-pointer w-100 text-rose01 text-decoration-none">
                                      {item.field_value.cta?.field_value?.label}

                                      <Image
                                        className="ml-2 icon-overlay"
                                        name="arrow-right-yellow"></Image>
                                    </Link>
                                  </div>
                                );
                              } else if (itemIndex < 4) {
                                return (
                                  <Link
                                    internalLink
                                    key={itemIndex}
                                    className="field-menu-desktop cursor-pointer text-grey05 text-decoration-none"
                                    values={item.field_value.cta?.field_value}>
                                    {item.field_value.cta?.field_value?.label}
                                  </Link>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </div>
                        </div>

                        <div className="col-18">
                          <div className="row">
                            {field.product &&
                              field?.product?.field_value.map(
                                (prod, productIndex) => {
                                  return (
                                    <>
                                      {productIndex < 4 && (
                                        <div
                                          className="col-6 d-flex justify-content-center"
                                          key={productIndex}>
                                          <div className="Header__card bg-grey02 text-grey05">
                                            <div className="Header__card-content">
                                              <Link
                                                className="text-content h-100 px-2 pt-2 pb-3"
                                                values={
                                                  prod.field_value.product
                                                    .field_value?.menu_cta
                                                    ?.field_value
                                                }>
                                                {prod.field_value.product
                                                  ?.field_value && (
                                                  <Picture
                                                    className="card-logo-desktop"
                                                    image={
                                                      prod.field_value.product
                                                        ?.field_value.menu_logo
                                                        ?.field_value
                                                    }
                                                  />
                                                )}

                                                <div className="position-relative">
                                                  {prod.field_value.product
                                                    .field_value?.menu_up_title
                                                    ?.field_value && (
                                                    <div className="UpTitle mb-1 bg-yellowBtn">
                                                      <span className="Poppins text-white font-weight-bold px-1 pt-1">
                                                        {
                                                          prod.field_value
                                                            .product.field_value
                                                            ?.menu_up_title
                                                            ?.field_value
                                                        }
                                                      </span>
                                                    </div>
                                                  )}
                                                  <span className="label-card d-block text-grey01 mb-1">
                                                    {
                                                      prod?.field_value?.product
                                                        ?.field_value?.menu_title
                                                        ?.field_value
                                                    }
                                                  </span>
                                                </div>
                                              </Link>
                                              <div className="img-container">
                                                <Picture
                                                  image={
                                                    prod?.field_value?.product
                                                      ?.field_value?.menu_image
                                                      ?.field_value
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  );
                                },
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            } else {
              return null;
            }
          })}

          {isCategoryOpen.open && (
            <div
              className="overlay"
              onClick={() => {
                setIsCategoryOpen({ open: false, index: null });
              }}></div>
          )}
        </div>

        {/* Breadcrumbs */}
        {!sticky.isSticky && breadcrumbs && breadcrumbs.length > 0 && (
          <div className="container d-none d-md-block">
            <div className="row">
              <div className="col-24">
                <div className="position-relative px-2 px-md-0">
                  <div className="breadcrumbs mt-1 d-flex flex-direction-row align-items-center">
                    {breadcrumbs.map((breadcrumb, index) => (
                      <span
                        key={index}
                        className={`d-flex flex-direction-row  ${
                          props.info?.dark_breadcrumbs === '0'
                            ? 'text-grey01'
                            : 'text-grey05'
                        }`}>
                        {breadcrumb.href ? (
                          <>
                            <Link
                              values={breadcrumb}
                              className={`Poppins text  ${
                                props.info?.dark_breadcrumbs === '0'
                                  ? 'text-decoration-underline text-grey01'
                                  : 'text-decoration-none text-grey05'
                              }`}>
                              {breadcrumb.title}
                            </Link>
                            <span className="symbol">&nbsp; &gt; &nbsp;</span>
                          </>
                        ) : (
                          <span
                            className={`Poppins font-weight-bold text ${
                              props.info?.dark_breadcrumbs === '0'
                                ? 'text-grey01'
                                : 'text-grey05'
                            }`}>
                            {breadcrumb.title}
                          </span>
                        )}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

HeaderModule.defaultProps = {
  info: { black_header: '1' },
  logoLink: null,
  myAreaHref: null,
  myAreaLabel: null,
  searchBox: null,
  showMenu: true,
  showSearch: true,
  breadcrumbs: [],
};

export default HeaderModule;
