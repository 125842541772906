import './core/src/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { stores } from './core/src/stores';
import './core/src/i18n';
import theme from './core/src/theme';
import App from './core/src/App';
import * as serviceWorker from './core/src/serviceWorker';

ReactDOM.render(
  <Provider {...stores}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
