// @flow
/*global gproxy*/
import _ from 'lodash';
import qs from 'query-string';
import storage from '../utils/storage';
import { observable, action } from 'mobx';
const paymentDataKey = 'paymentData';

class PaymentStore {
  @observable storedPayments = false;
  static RES_DETAILS_EXCLUSION_LIST = [
    'maPayment',
    'maType',
    'orderId',
    'resultCode',
    'type',
    '',
  ];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action
  hasStoredItems(v) {
    this.storedPayments = v;
  }

  async getStoredPaymentMethods(country, amount) {
    const { locale } = this.rootStore.uiStore;
    const res = await gproxy.customer.payment.stored.list({
      amount: amount,
      channel: 'Web',
      countryCode: country,
      shopperLocale: locale,
      blockedPaymentMethods: ['c_invoice', 'c_cash', 'openbanking_UK'],
    });
    return res;
  }

  async removeStoredPaymentMethods(paymentMethod) {
    if (
      paymentMethod &&
      paymentMethod.props &&
      paymentMethod.props.storedPaymentMethodId
    ) {
      const res = await gproxy.customer.payment.stored.remove({
        id: paymentMethod.props.storedPaymentMethodId,
      });

      return res;
    } else {
      return false;
    }
  }

  async getPaymentMethods(country, amount) {
    const { locale } = this.rootStore.uiStore;
    const res = await gproxy.customer.payment.create({
      amount: amount,
      channel: 'Web',
      countryCode: country,
      shopperLocale: locale,
      blockedPaymentMethods: ['c_invoice', 'c_cash', 'openbanking_UK'],
    });
    return { paymentMethods: res.paymentMethods, origins: res.origins };
  }

  async getAdditionalDetails(data) {
    return await gproxy.customer.payment.details(data);
  }

  async makePayment(data, type) {
    const { reference } = data;

    const req = {
      ...data,
      enableRecurring: true,
      shopperInteraction: 'Ecommerce',
      recurringProcessingModel: 'CardOnFile',
      shopperReference: this.rootStore.userStore.user.UID,
      origin: window.location.origin,
      redirectFromIssuerMethod: 'GET',
      redirectToIssuerMethod: 'GET',
      shopperEmail: this.rootStore.userStore.user.email,
      returnUrl: `${
        window.location.href.split('?')[0]
      }?orderId=${reference}&maPayment=true&maType=${type}`,
    };
    const res = await gproxy.customer.payment.authorize({
      ...req,
      amount: req.amount.asLongValue(),
    });
    if (res) {
      storage.store(paymentDataKey, res.paymentData || null);
    }
    return res;
  }

  async manageRedirect() {
    const paramers = qs.parse(window.location.search);
    if (paramers.maPayment) {
      const details = _.omit(paramers, PaymentStore.RES_DETAILS_EXCLUSION_LIST);
      const paymentData = storage.pop(paymentDataKey);

      return this.getAdditionalDetails({ details, paymentData });
    } else {
      return false;
    }
  }

  getConfig(paymentMethods, hideCVC = false) {
    const { locale, siteId } = this.rootStore.uiStore;
    return {
      paymentMethodsResponse: paymentMethods.data
        ? paymentMethods.data
        : paymentMethods,
      enableRecurring: true,
      //TODO:  origin key
      originKey: paymentMethods.origins.originKeys[window.location.origin],
      // originKey:
      //   'pub.v2.8215475649840997.aHR0cDovL2xvY2FsaG9zdDozMDAw.z0I_zO0RmBzpUB7VBPxpv_vguIVNrFzhDbYbzFyK6B8',
      locale: locale,
      environment: !siteId.endsWith('US')
        ? process.env.REACT_APP_PAYMENT_ENVIRONMENT
        : process.env.REACT_APP_PAYMENT_US_ENVIRONMENT, // Europe: live, US: live-us
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: false,
          holderNameRequired: true,
          enableStoreDetails: false,
          hideCVC: hideCVC,
        },
        paypal: {
          merchantId: 'L3NW5KJ93YD4E',
          environment: process.env.REACT_APP_PAYMENT_ENVIRONMENT,
          intent: 'authorize',
          onCancel: function (data, dropin) {
            dropin.setStatus('ready');
          },
        },
        c_invoice: {
          name: 'Rechnung',
        },
        c_cash: {
          name: 'Free subscription',
        },
      },
      showPayButton: false,
    };
  }

  getAmount = (currency) => {
    switch (currency) {
      case 'CZK':
        return 150;
      case 'PLN':
        return 24;
      default:
        return 5;
    }
  };
}

export default PaymentStore;
